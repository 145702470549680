import { firebase } from "@project/shared"
import { Dispatch, createContext, useEffect, useState } from "react"
import { getCompanyByUid } from "../services/company"

type ContextProps = {
  loading: boolean
  user: firebase.User | null
  authenticated: boolean
  setUser: any
  currentUser: any
  setCurrentUser: Dispatch<unknown>
  companyInfo: any
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  children: React.ReactNode
  currentUser: unknown
  setCurrentUser: Dispatch<unknown>
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { loading, user, setUser, children, currentUser, setCurrentUser } =
    props
  const [companyInfo, setCompanyInfo] = useState(null)

  useEffect(() => {
    if (user != null) {
      getCompanyByUid({ queryKey: ["getCompanyByUid", user?.uid] })
        .then((res) => {
          setCompanyInfo(res?.data)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        currentUser,
        setCurrentUser,
        companyInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const getUserMetadata = async (user: firebase.User | null) => {
  const metadata = await user?.getIdTokenResult()
  return {
    uid: metadata?.claims["user_id"] || "",
    role: metadata?.claims["role"] || "",
  }
}
